.loginbutton {
  width: 100%;
  margin-bottom: 20px;
  background-color: $color-accent;
  border-color: $color-accent;
}

.forgotPassword {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 24px;
}

.pwVisibilityIcon {
  width: 24px;
  padding: 7px
}

.rememberme {
  margin-left: 1.25em;
}

.logoutbutton {
  background-color: $color-accent;
  border-color: $color-accent;
}
