.snackBarContainer {
  display: flex;
  z-index: 200;
  position: absolute;
  top: 10px;
  width: 100%;
  justify-content: center;
}

.snackBarBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}