.sharePage {
  text-align: right; 
  padding: 0px 15px 10px 15px;

  button {
    background-color: gray;
    color: white;
    border: none;
    text-align: center;
    padding: 2px 5px 2px 5px;
    font-size: 12px;
    border-radius: 12px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.sharePageOptions {
  background-color: gray;
  color: white;
  border: none;
  text-align: center;
  padding: 2px 5px 2px 5px;
  font-size: 16px;
  border-radius: 12px;
}

