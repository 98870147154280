// Floating admin buttons
.admin_floatingContainer {
  display: block;
  position: absolute;
  right: 5px;
  margin: -22px 0px auto auto;
  z-index: 9;
}

//admin buttons to be used in admin screens
.admin_button {
  border-radius: 50%;
  border: none;
  height: 32px;
  width: 32px;
  padding: 1px;
  @include themify($themes) {
    color: themed('colorButtonText');
  }
}

.admin_button_small {
  border-radius: 50%;
  border: none;
  height: 26px;
  width: 26px;
  padding: 1px;
  margin: 0px 0px 0px -3px;
  @include themify($themes) {
    color: themed('colorButtonText');
  }
}

.admin_button_large {
  border-radius: 50%;
  border: none;
  height: 40px;
  width: 40px;
  padding: 1px;
  @include themify($themes) {
    color: themed('colorButtonText');
  }
}


.admin_button_theme {
  @include themify($themes) {
    background-color: themed('colorButton');
  }

  &:hover {
    @include themify($themes) {
      background-color: themed('colorButtonHover');
    }  
  }
}

.admin_button_red {
  background-color: $color-red;

  &:hover {
    background-color: $color-red-hover;
  }
}

.admin_button_lightgray {
  background-color: $color-mtblightgray;

  &:hover {
    background-color: $color-mtblightgray-hover;
  }
}

.adminToolbar_Navbar {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  margin-right: 0px;
  height: 46px;
}

.adminToolbar_alertContainer {
  width: 100%;
  display: inline-block;
  margin: 7px 0px 7px 7px;
}

.adminToolbar_alertText {
  
}

.adminToolbar_buttonContainer {
  display: inline-flex;
}

.adminToolbar_button {
  margin: 7px 0px 7px 7px;
}

// Add line Button component
.admin_addLineButtonContainer {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.admin_addLineButtonText {
  color: $color-mtbgreen;
  font-size: medium;
  padding-left: 5px;
}

.admin_submitButton {
  background-color: $color-mtbgreen;
  border-color: $color-mtbgreen;
}

.admin_cancelButton {
  background-color: $color-mtbdark;
  border-color: $color-mtbdark;
}