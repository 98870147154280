.form {
  display: flex;
  flex-wrap: wrap;

  input, textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;

    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    @include themify($themes) {
      border: 1px solid themed('colorFieldsBorder');
      color: themed('colorText');
    }

    &[disabled] {

      @include themify($themes) {
        background: themed('colorBackgroundBody');
      }

      &:focus, &:active {

        @include themify($themes) {
          border-color: themed('colorBorder');
        }
      }
    }

    &:focus, &:active {
      outline: none;
      border-color: $color-accent;
    }
  }

  textarea {
    min-height: 85px;
  }

  &.form--horizontal {

    .form__form-group {
      display: flex;
      flex-wrap: wrap;
    }

    .form__form-group-field {
      width: calc(100% - 80px);
      padding-left: 10px;
      margin-left: 80px;
    }

    .form__form-group-label {
      width: 80px;
      max-height: 32px;
      line-height: 18px;
      margin: auto 0;

      & + .form__form-group-field {
        margin-left: 0;
      }
    }

    .form__form-group-description {
      margin-left: 90px;
    }

    .form__button-toolbar {
      margin-left: 0;
    }

    .form__form-group-input-wrap--error-above {
      margin-bottom: 15px;

      .form__form-group-error {
        top: -28px;
      }
    }

    @media screen and (min-width: 480px) {

      .form__form-group-label {
        width: 120px;
      }

      .form__form-group-field {
        width: calc(100% - 120px);
        margin-left: 120px;
        padding-left: 20px;
      }

      .form__form-group-description, .form__button-toolbar {
        margin-left: 140px;
      }
    }
  }

  &.form--preview {
    display: flex;

    & > div:nth-child(2) {
      margin-right: 50px;
    }

    .form__form-group {
      margin-bottom: 10px;
      width: auto;
      min-height: 18px;
    }

    .form__select-color {
      display: flex;
      flex-wrap: wrap;
      max-width: 84px;
      margin-right: 40px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  &.form--justify {
    display: flex;
    flex-wrap: wrap;

    .form__form-group {
      width: 33.3333%;
    }

    .form__button-toolbar {
      width: 100%;
    }
  }

  .form__form-group-select {
    width: 100%;
    height: 32px;
    font-size: 12px;

    .Select-control {
      height: 32px;
      border-radius: 0;
      transition: all 0.3s;
      background: transparent;

      @include themify($themes) {
        border: 1px solid themed('colorFieldsBorder');
      }
    }

    .Select-placeholder, .Select-input {
      height: 30px;
    }

    .Select-input {
      width: 100%;
    }

    input {
      width: 100% !important;
      border: none;
      padding: 0;
    }

    .Select-multi-value-wrapper {

      .Select-input {
        width: inherit;
      }

      .Select-value {
        background: transparent;
        border-color: $color-blue;
      }

      .Select-value-label {

        @include themify($themes) {
          color: themed('colorText') !important;
        }
      }

      .Select-value-icon {
        border-color: $color-blue;
      }
    }

    .Select-menu-outer {
      top: calc(100% + 1px);
      border-radius: 0;
      box-shadow: none;
      font-size: 12px;
      animation: open 0.3s ease-in-out;
      overflow: hidden;

      @include themify($themes) {
        border: 1px solid themed('colorBorder');
        background: themed('colorBackground');
      }
    }

    @keyframes open {
      0% {
        max-height: 0
      }
      100% {
        max-height: 200px
      }
    }

    .Select-option {
      transition: all 0.3s;
      border-radius: 0;
      display: flex;

      @include themify($themes) {
        background: themed('colorBackground');
        color: themed('colorText');
      }

      &.is-focused {
        @include themify($themes) {
          background: themed('colorHover');
        }
      }
    }

    &.is-focused, &.is-focused:not(.is-open) {

      .Select-control {
        border-color: $color-accent;
        box-shadow: none;
        background: transparent;
      }
    }

    .form__form-group-select-color {
      display: block;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: auto 0 auto 5px;
    }
  }
}

.form__max400 {
  //width: calc(50% - 15px);
  max-width: 400px;
  height: 100%;

  &:first-child {
    margin-right: 30px;
  }

  .form__button-toolbar {
    float: right;
  }
}

.form__form-group {
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
}

.form__form-group-file {

  label {
    border-radius: 2px;
    line-height: 18px;
    font-size: 12px;
    padding: 4px 20px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;

    @include themify($themes) {
      border: 1px solid themed('colorFieldsBorder');
      color: themed('colorText');
    }

    &:hover {

      @include themify($themes) {
        background: themed('colorFieldsBorder');
      }
    }
  }

  span {
    padding-left: 10px;
  }

  input {
    display: none;
  }
}

.form__form-group-icon {
  margin-top: 35px;

  @include themify($themes) {
    background: themed('colorFieldsBorder');
    border: 1px solid themed('colorFieldsBorder');
    color: themed('colorText');
  }

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
}

.formFeedbackInvalid {
  display: block;
}


