.card-topbar {
  width: 100%;
  position: relative; 
  padding-right: 6px;
  top: -25px;
  height: 30px;
  background-color: transparent;
}

.card-topbar__controls-wrap {
  position: relative;
}

.card-topbar__icon {
  font-size: 30;
  color: $color-accent;
  vertical-align: middle;
}

.card-topbar__controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 50px;
  @include directify($directions) {
    #{directed('padding-left')}: 0px;
  }
  position: relative;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }

}

.card-topbar__control-pagination {
  display: flex;
  align-content: flex-end;
  @include directify($directions) {
    #{directed('margin-right')}: 0px;
    #{directed('margin-left')}: 10px;
  }
}

.card-topbar__control-pagination__link {
  background: transparent;
  border: none;
  color: $color-additional;
  height: 28px;
  font-size: 10px;
  padding: 5px;
  text-align: center;
  min-width: 28px;
  transition: all 0.3s;
  @include directify($directions) {
    #{directed('margin-right')}: 2px;
  }

  &:hover {
    color: $color-accent;
    background: transparent;
  }

  &:focus, &:active {
    box-shadow: none;
  }

  svg {
    transition: 0.3s;
  }

  &.card-topbar__control-pagination__link--arrow {
    border-radius: 50% !important;
    width: 28px;
    height: 28px;

    @include themify($themes) {
      background: themed('colorButton');
    }

    &:hover {
      @include themify($themes) {
        background: themed('colorButtonHover');
      }
  
      svg {
        fill: white;
      }
    }
  }
}

.card-topbar__control-pagination__item {

  &.active .card-topbar__control-pagination__link {
    background-color: transparent;
    font-weight: bold;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  &.disabled {

    .card-topbar__control-pagination__link svg {

      @include themify($themes) {
        fill: themed('colorButtonDisabled');
      }
    }
  }
}

.card-topbar__control-pagination__link-icon {
  width: 13px;
  height: 13px;
  @include themify($themes) {
    fill: themed('colorButtonText');
  }
}

.card-topbar__control-pagination__wrap {
  display: flex;
  margin-top: 15px;

  &.card-topbar__control-pagination__wrap--small {
    margin-top: 0;
    display: block;
  }
}

.card-topbar__control-pagination-info {
  color: $color-additional;
  font-size: 12px;
  @include directify($directions) {
    #{directed('margin-right')}: 0px;
    #{directed('margin-left')}: 10px;
  }
  margin-top: auto;
  margin-bottom: auto;
}

.card-topbar__control-search {
  display: flex;
  @include directify($directions) {
    #{directed('margin-right')}: 0px;
    #{directed('padding-left')}: 16px;
  }

  input {
    height: 28px;
    @include directify($directions) {
      #{directed('padding-left')}: 10px;
      #{directed('border-top-left-radius')}: 14px;
      #{directed('border-bottom-left-radius')}: 14px;
    }
    transition: all 0.3s;
    border: 1px solid transparent;
    width: 120px;

    @include themify($themes) {
      color: themed('colorText');
      background: themed('colorHover');
      border-color: themed('colorButton');
    }

    &:focus {
      //border-color: $color-accent;
      outline: none;

      & + .card-topbar__control-search-icon {
        background-color: $color-accent;

        svg {
          fill: white;
        }
      }
    }

    &::-webkit-input-placeholder {

      @include themify($themes) {
        color: themed('colorIcon');
      }
    }
    &::-moz-placeholder {

      @include themify($themes) {
        color: themed('colorIcon');
      }
    }
    /* Firefox 19+ */
    &:-moz-placeholder {

      @include themify($themes) {
        color: themed('colorIcon');
      }
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {

      @include themify($themes) {
        color: themed('colorIcon');
      }
    }
  }
}

.card-topbar__control-search-icon {
  width: 28px;
  padding: 5px;
  height: 28px;
  @include directify($directions) {
    #{directed('border-top-right-radius')}: 14px;
    #{directed('border-bottom-right-radius')}: 14px;
  }
  transition: 0.3s;

  @include themify($themes) {
    //background: themed('colorFieldsBorder');
    background: themed('colorButton');
  }

  svg {
    height: 16px;
    width: 16px;
    margin-top: -3px;
    transition: 0.3s;

    @include themify($themes) {
      fill: themed('colorButtonText');
    }
  }
}
