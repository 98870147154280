.infoCardList__icon {
  font-size: 30;
  color: $color-accent;
  vertical-align: middle;
 }

 .infoCardList__cardBody {
  }

 .infoCardList__image {
}