.programDetailRow__top {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 0px;
}

.programDetailRow__bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  white-space: pre-wrap;
}

.programDetail__hour {
  text-align: right;
}

.program_detail_location {
  color: $color-mtbgreen;
}

// Floating admin buttons
.admin_editLine {
  min-height: 200px;
  display: block;
  position: absolute;
  right: 0px;
  margin: 6px 12px 0px 0px;
  z-index: 9;
}

// program legend 
.programLegend__container {
  display: flex;
  margin: 15px 0px 0px 0px
}

.programLegend__item {
  padding-right: 15px;
  display: flex;
  align-items: center;
}

